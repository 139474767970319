import { memo } from "react";
import PropTypes from "prop-types";
import NegociatedItem from "app/pages/FicheProduit/NegociatedList/NegociatedItem";
import { FormattedMessage } from "react-intl";
import "./NegociatedList.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const NegociatedList = ({ negociatedList, specialOffer, offerWithTransportLabel }) => {
	return (
		<div className="negociated-list" data-testid="negotiated-list">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL}
				variantMD={TYPOGRAPHY_VARIANTS.XL4}
				isBold
				className="negociated-list__title"
				component="h2"
			>
				<FormattedMessage id="product.page.negociated.title" />
			</Typography>
			<ul className="negociated-list__list">
				{negociatedList.map((negociated, index) => {
					return (
						<li key={index} className="negociated-list__item">
							<NegociatedItem
								type={negociated.type}
								label={negociated.label}
								isTransportOnly={negociated.isTransportOnly}
								offerWithTransportLabel={offerWithTransportLabel}
								specialOffer={specialOffer}
							/>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

NegociatedList.propTypes = {
	negociatedList: PropTypes.array,
	specialOffer: PropTypes.object,
	offerWithTransportLabel: PropTypes.string,
};

NegociatedList.defaultProps = {
	negociatedList: [],
	specialOffer: {},
};

export default memo(NegociatedList);
