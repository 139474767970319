import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import "./Formalities.scss";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

/* eslint react/no-danger: 0 */
const Formalities = ({ intro, vaccine, visa, identity, country }) => {
	return (
		<div className="formalities">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL}
				isBold
				className="formalities__headline"
				component="h3"
			>
				<FormattedMessage
					id="useful.information.formalities.headline"
					values={{ country: country }}
				/>
			</Typography>
			<div className="formalities__intro" data-testid="formalities-intro">
				<Paragraphs paragraphs={intro} enableHTMLFormatting />
			</div>
			<div className="formalities-list">
				{vaccine && (
					<div className="formalities-list__item" data-testid="formalities-vaccine">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="formalities-list__headline"
							component="h4"
						>
							<FormattedMessage id="useful.informtion.vaccine.title" />
						</Typography>
						<Paragraphs paragraphs={vaccine} enableHTMLFormatting />
					</div>
				)}

				{visa && (
					<div className="formalities-list__item" data-testid="formalities-visa">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="formalities-list__headline"
							component="h4"
						>
							<FormattedMessage id="useful.informtion.visa.title" />
						</Typography>
						<Paragraphs paragraphs={visa} enableHTMLFormatting />
					</div>
				)}

				{identity && (
					<div className="formalities-list__item" data-testid="formalities-identity">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
							className="formalities-list__headline"
							component="h4"
						>
							<FormattedMessage id="useful.informtion.identity.title" />
						</Typography>
						<Paragraphs paragraphs={identity} enableHTMLFormatting />
					</div>
				)}
			</div>
		</div>
	);
};

Formalities.propTypes = {
	country: PropTypes.string,
	vaccine: PropTypes.string,
	intro: PropTypes.string,
	visa: PropTypes.string,
	identity: PropTypes.string,
};

export default memo(Formalities);
