import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./Transport.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const Transport = ({ description }) => {
	return (
		<div className="transport">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL}
				isBold
				className="transport__headline"
				component="h3"
			>
				<FormattedMessage id="useful.information.transports.headline" />
			</Typography>
			<div className="transport__description">
				<Paragraphs paragraphs={description} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

Transport.propTypes = {
	description: PropTypes.arrayOf(PropTypes.string),
};

Transport.defaultProps = {
	description: [],
};

export default memo(Transport);
