import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./TravelDocuments.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TravelDocuments = ({ description }) => {
	return (
		<div className="travel-documents">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL}
				isBold
				className="travel-documents__headline"
				component="h3"
			>
				<FormattedMessage id="useful.information.travel.doc.headline" />
			</Typography>
			<div className="travel-documents__description">
				<Paragraphs paragraphs={description} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

TravelDocuments.propTypes = {
	description: PropTypes.arrayOf(PropTypes.string),
};

TravelDocuments.defaultProps = {
	description: [],
};

export default memo(TravelDocuments);
