import { memo } from "react";
import PropTypes from "prop-types";
import AdditionalSection from "app/pages/FicheProduit/components/AdditionalSections/AdditionalSection";
import "./AdditionalSections.scss";
import MoreDetails from "app/pages/.shared/MoreDetails";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const AdditionalSections = ({ headline, blocks = [], moreDetails = [] }) => {
	const itineraryNodes = blocks.map(block => {
		return (
			<div className="additional-sections__item" key={block.title}>
				<AdditionalSection {...block} />
			</div>
		);
	});

	return (
		<div className="additional-sections">
			{headline && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.XL4}
					isBold
					className="additional-sections__title"
					component="h2"
				>
					{headline}
				</Typography>
			)}
			<div>{itineraryNodes}</div>
			{moreDetails.length > 0 && (
				<div className="additional-sections__details">
					<MoreDetails
						expandedLabel={<FormattedMessage id="product.page.less.details" />}
						collapsedLabel={<FormattedMessage id="product.page.more.details" />}
					>
						<Paragraphs paragraphs={moreDetails} enableHTMLFormatting />
					</MoreDetails>
				</div>
			)}
		</div>
	);
};

AdditionalSections.propTypes = {
	headline: PropTypes.string,
	blocks: PropTypes.array,
	moreDetails: PropTypes.array,
};

export default memo(AdditionalSections);
